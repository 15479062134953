import request from './request'
export async function requestGet(url, obj, urlParm, headers = {}) {
  let path = ''
  if (url.url) {
    path = url.url
    obj = url.obj
  }
  
  const res = await request({
    url: path,
    method: 'get',
    params: obj,
    headers: headers
  })
  return new Promise((resolve) => {
    resolve(res)
  })
}

export function requestPost(url, obj, urlParam, headers = {}) {
  let path
  let params = {}
  if (url.url) {
    path = url.url
    obj = url.obj
    params = url.params
  }
  if (url.headers) {
    headers = url.headers
  }
  if (urlParam) {
    path += urlParam
  }
  return request({
    url: path,
    method: 'post',
    headers: headers,
    ...params,
  })
}

export function requestGetJson(url, obj, urlParm) {
  let path = ''
  if (url.url) {
    path = url.url
    obj = url.obj
  }
  return request({
    url: path,
    method: 'get',
    params: obj,
    dataType: 'json',
    headers: {}
  })
}

export function requestPostJson(url, obj) {
  let path = ''
  if (url.url) {
    path = url.url
    obj = url.obj
  }
  return request({
    url: path,
    method: 'post',
    data: obj,
    dataType: 'json',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
