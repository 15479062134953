import axios from 'axios'

const service = axios.create({
  baseURL: '/',
  timeout: 50000,
  'Content-Type': 'application/x-www-form-urlencoded'
})

service.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
}

service.interceptors.request.use(
  (config) => {
    config.headers = config.headers || service.defaults.headers
    config.headers['returnContentType'] = 'json'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    return res;
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
