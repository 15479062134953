import Vue from 'vue'
import App from './App.vue'
import router from './router'
import prototypeExpand from '@/api/prototypeExpand'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.use(prototypeExpand)
Vue.config.productionTip = false

// document.querySelector('#version').onload = () => {
//   new Vue({
//     router,
//     render: h => h(App)
//   }).$mount('#app')
// }

const interval = setInterval(() => {
  if(window.versionLoaded){
    clearInterval(interval);
      new Vue({
        router,
        render: h => h(App)
      }).$mount('#app')
  }
},100);