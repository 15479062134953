<template>
  <div>
    <div class="footer">
      <div class="footer-top">
        <div class="footer-top-content maxWidth">
          <a :href="data.copyRight.clauseUrl">隐私条款</a>
          <a :href="data.copyRight.hrefUrl">用户协议</a>
        </div>
      </div>
      <div class="copyRight">
        <div class="copyRight-content maxWidth">
          <div class="copyRight-left">
            <img :src="data.copyRight.logo" alt="" class="logo">
            <span v-html="data.copyRight.title" class="text"></span>
            <img src="@/assets/bpm.png" alt=""> 
            <a class="download" :href="data.copyRight.href" :target="data.copyRight.target" v-html="data.copyRight.content"></a>
          </div>
          <div class="copyRight-right">
            <div class="copyRight-right-info">
              <div class="text phone">{{ data.copyRight.phone }}</div>
              <div class="text date">{{ data.copyRight.date }}</div>
              <div class="text name">{{ data.copyRight.name }}</div>
              <div class="text address">{{ data.copyRight.address }}</div>
            </div>
            <div class="copyRight-right-qrCode">
              <img :src="data.copyRight.qrCode" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'el-footer',
    data() {
      return {
        data: window.data
      }
    },  
    computed:{
      isBlack() {
        return this.$route.name == 'redAlert' || this.$route.name == 'aboutUs'
      },
    }
  }
</script>
<style lang="less" scoped>
  .footer{
    width: 100%;
    // background: #F0F0F0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    .footer-top{
      width: 100%;  
      background: #242A35;
      .footer-top-content{
        height: 68px;
        display: flex;
        align-items: center;
      }
      a{
        display: inline-block;
        font-size: 16px;
        color: #A2A2A2;
        text-decoration: none;
        margin-right: 48px;
        &:hover{
          color: #fff;
        }
      }
    }
    .copyRight{
      width: 100%;
      height: 215px;
      overflow: hidden;
      box-sizing: border-box;
      padding-top: 48px;
      color: #fff;
      background: #3B4250;
      .copyRight-content{
        display: flex;
        justify-content: space-between;
        .copyRight-left{
          color: #9CA2AD;
          font-size: 12px;
          .logo{
            width: 154px;
            height: 40px;
            display: block;
          }
          .text{
            display: block;
            margin-top: 42px;
          }
          a{
            color: #9CA2AD;
            text-decoration: none;
          }
          img{
            width: 12px;
            margin-right: 4px;
          }
        }
        .copyRight-right{
          display: flex;
          .copyRight-right-info{
            color: #9CA2AD;
            font-size: 16px;
            text-align: right;
            .phone{
              font-size: 32px;
              color: #FFFFFF;
              line-height: 32px;
            }
            .date{
              margin-bottom: 17px;
            }
            .name,.address{
              font-size: 12px;
            }
          }
          .copyRight-right-qrCode img{
            width: 96px;
            height: 96px;
            margin-left: 20px;
          }
        }
      }
    }
  }
</style>
  