<template>
  <div class="service-page">
    <div class="clueform-box">
      <img :src="data.banner" alt="" class="clueform-bg" />
      <div class="clueform-content maxWidth">
        <div class="clueform-inner">
          <div class="clueform-title">
            {{ data.formInfo.title }} <span>{{ data.formInfo.subtitle }}</span>
          </div>
          <el-form :model="form" ref="clueForm" class="clueForm">
            <el-form-item prop="countryId">
              <el-select v-model="form.countryId" placeholder="请选择签证国家" @change="changeCountry" popper-class="horizontal">
                <el-option
                  v-for="item in countryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="cityId">
              <el-select v-model="form.cityId" placeholder="请选择常住城市" popper-class="horizontal">
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="typeId">
              <el-select v-model="form.typeId" placeholder="请选择签证类型" popper-class="vertical">
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                  <div class="label">{{item.name}}</div>
                  <div class="tip">{{item.tips}}</div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input maxlength="11" v-model="form.mobile" placeholder="请输入手机号" @input="mobileInput($event,'mobile')"></el-input>
            </el-form-item>
            <el-form-item prop="verifyCode" class="verifyCode">
              <el-input v-model="form.smsCode" class="code" placeholder="请输入验证码" @input="mobileInput($event,'smsCode')"></el-input>
              <div @click="sendOTP" :class="{ number: disStartSecond > 0 }" class="get-code">
                {{
                  disStartSecond > 0 ? `${disStartSecond}s后重试` : '获取验证码'
                }}
              </div>
            </el-form-item>
          </el-form>
          <div class="clueform-count">
           <img :src="data.formInfo.numImg" alt=""> <span class="count__tip">位用户已通过51签证去往全球</span>
          </div>
          <div class="clueform-submit" @click="submitHandler">{{ data.formInfo.buttonText }}</div>
          <div class="clueform-agreement">
            <el-checkbox v-model="checked"  label=""></el-checkbox>
            <div class="clueform-agreement__label">
              已阅读并同意
              <a :href="data.formInfo.userAgreement" target="_blank">《用户服务协议》</a>
              <a :href="data.formInfo.privacyPolicy" target="_blank">《隐私政策》</a>
              ，授权51签证为您提供签证服务
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="service-box">
      <div class="title">{{ data.service.title }}</div>
      <div class="service-content maxWidth">
        <div class="service-item" v-for="(item,index) in data.service.serviceList" :key="index">
          <img :src="item" alt="">
        </div>
      </div>
    </div>

    <div class="newVisa-box">
      <div class="title">{{ data.newVisa.title }}</div>
      <div class="newVisa-content maxWidth">
        <swiper :options="swiperOptions" class="swiper-box" ref="mySwiper">
          <swiper-slide
            class="swiper-item"
            v-for="(item, index) in data.newVisa.bannerList"
            :key="index"
          >
            <!-- <div class="swiper-cont"> -->
              <img
                class="banner-img"
                :src="item.img"
                draggable="false"
              />
              <div class="swiper-info">
                <div class="desc">{{ item.desc }}</div>
                <div class="item-price">
                  <div class="item-left">签证费<span class="value">{{ item.price }}</span></div>
                  <div class="item-right">
                    <img :src="item.headImg" alt="">
                    <span>{{ item.people }}</span>
                  </div>
                </div>
              </div>
            <!-- </div> -->
          </swiper-slide>
          
        </swiper>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>

    <div class="flowGuide-box">
      <div class="title">{{ data.flowGuide.title }}</div>
      <div class="flowGuide-content maxWidth">
        <div class="flowGuide-tabs">
          <div class="flowGuide-tab" v-for="(item,index) in data.flowGuide.list" :key="index" @click="changeHandler(index)">
            <div class="flowGuide-tab__index">{{ index+1 }}</div>
            <div class="flowGuide-tab__top">
              <img :src="active==index ? item.iconed : item.icon" alt="">
              <div :class="['tab-title',{active: active == index}]">{{ item.title }}</div>
              <div class="tab__top-line" v-if="active == index"></div>
            </div>
            <div class="flowGuide-tab__desc">{{ item.desc }}</div>
            <div class="flowGuide-tab__triangle" v-if="active == index">
              <div class="arrow left"></div>
              <div class="arrow right"></div>
            </div>
          </div>
        </div>
        <div class="flowGuide-panel">
          <div class="flowGuide-panel-item" v-for="(item,index) in data.flowGuide.list[active].content" :key="index">
            <div class="flowGuide-title">{{ item.title }}</div>
            <div class="flowGuide-subtitle">{{ item.subtitle }}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="successVisible"
      width="318px"
      :close-on-click-modal="false"
      :before-close="handleClose">
        <div class="dialog-content">
          <div class="title">提交办理签证成功</div>
          <div class="desc">您的签证办理已收到 <br> 扫描二维码获取签证材</div>
          <div class="qrcode">
            <img :src="data.dialogQrCode" alt="">
            <div class="qrcode-title">微信扫码咨询客服</div>
            <div class="qrcode-subtitle">
              <img :src="data.copyRight.weixin" alt="">
              获取签证资料</div>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

export default {
  name: "HomeView",
  components: {
    swiper,
    swiperSlide
  },
  data(){
    return {
      data: window.data,
      active: 0,
      form: {},
      countryList: [],
      cityList: [],
      typeList: [],
      disStartSecond: 0,
      changeDisStartInterval: null,
      codeOverdueTime: 0, // 验证码的过期时间, 要用过期时间推算倒计时, 因为定时器存在休眠的情况, 不能直接倒计时--
      checked: false,
      successVisible: false,
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 21,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      }
    }
  },
  created(){
    this.getCountryList();
    this.getCityList();
  },
  mounted(){
    
  },
  computed: {
    isValidPhone() {
      // 中国大陆手机号码正则（以1开头，第二位为3456789，后面跟着9位数字）
      const regex = /^1[3456789]\d{9}$/;
      return regex.test(this.form.mobile);
    }
  },
  methods: {
    changeHandler(index){
      this.active = index;
    },
    mobileInput(value, event){
      this.form[event] = value.replace(/\D/g, '');
    },
    getCountryList(){
      this.$postJson({
        url: 'api/query_visa_country_list'
      }).then((res) => {
        this.countryList = res?.data || []
      })
    },
    getCityList(){
      this.$postJson({
        url: 'api/query_permanent_city_list'
      }).then((res) => {
        this.cityList = res?.data || []
      })
    },
    getTypeList(){
      this.$postJson({
        url: 'api/query_country_visa_type_list',
        obj: { countryId: this.form.countryId}
      }).then((res) => {
        this.typeList = res?.data || []
      })
    },
    changeCountry(){
      this.$set(this.form, 'typeId', '');
      this.getTypeList();
    },
    handleGetVerifyCode() {
      this.$postJson({
        url: 'api/send_sms_code',
        obj: { 
          mobile: this.form.mobile,
          type: 1
        }
      }).then((res) => {
        if(res.code === 0){
          console.log(res.data);
        } else {
          this.codeOverdueTime = Date.now();
          this.$message(res.msg);
        }
      })
    },
    // 下一步是否要重新获取验证码
      sendOTP() {
        if( !this.form.mobile) {
          this.$message('请先输入手机号');
          return;
        }
        const overdueTime = this.codeOverdueTime;
        if (!overdueTime || Date.now() - overdueTime >= 0) {
          this.codeOverdueTime = Date.now() + this.data.formInfo.seconds * 1000;
          this.changeDisTime();
          this.handleGetVerifyCode();
          return false;
        }
        this.changeDisTime();
      },
    // 倒计时
    changeDisTime() {
      clearInterval(this.changeDisStartInterval);
      const handleDisStartArr = () => {
        this.disStartSecond = Math.round(
          (this.codeOverdueTime - Date.now()) / 1000
        );
        this.disStartSecond < 0 && (this.disStartSecond = 0);
      };
      handleDisStartArr();
      this.changeDisStartInterval = setInterval(() => {
        this.disStartSecond === 0
          ? clearInterval(this.changeDisStartInterval)
          : handleDisStartArr();
      }, 1000);
    },
    submitHandler(){
      if( !this.form.countryId) {
        this.$message('请选择签证国家');
        return;
      }
      if( !this.form.cityId) {
        this.$message('请选择常住城市');
        return;
      }
      if( !this.form.typeId) {
        this.$message('请选择签证类型');
        return;
      }
      if( !this.form.mobile) {
        this.$message('请填写手机号码后提交');
        return;
      }
      if( !this.isValidPhone) {
        this.$message('手机号不正确，请输入正确的手机号');
        return;
      }
      if( !this.form.smsCode) {
        this.$message('请输入验证码后重新提交');
        return;
      }
      if( !this.checked) {
        this.$message('您未勾选用户协议，请勾选后提交');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$postJson({
        url: 'api/submit_visa_user_info',
        obj: {
          ...this.form,
          channel: this.$route.query.version||'',
          scene	: 'web'
        }
      }).then((res) => {
        loading.close();
        if(res.code === 0){
          this.successVisible = true;
        }else{
          this.$message(res.msg);
        }
      }).catch((err) => {
        this.$message(err.msg);
      })
    },
    handleClose(){
      this.successVisible = false;
      this.form = {};
    }
  },
  beforeDestroy() {
    clearInterval(this.changeDisStartInterval);
  },
};
</script>
<style lang="less" scoped>
.service-page{
  font-family: PingFangSC, PingFang SC;
}
.clueform-box{
  width: 100%;
  height: 496px;
  position: relative;
  .clueform-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 496px;
    object-fit: cover;
  }
  .clueform-content{
    padding-top: 30px;
    position: relative;
    z-index: 1;
    .clueform-inner{
      width: 384px;
      height: 436px;
      padding: 16px 30px;
      background-color: #fff;
      box-sizing: border-box;
      .clueform-title{
        font-weight: 600;
        font-size: 20px;
        color: #262A31;
        margin-bottom: 16px;
        font-family: PingFangSC, PingFang SC;
        span{
          font-weight: 400;
          font-size: 14px;
          color: #7B7D80;
        }
      }
      /deep/ .el-select{
        width: 100%;
      }
      /deep/ .code{
        width: 188px;
      }
      /deep/ .el-input{
        height: 36px;
      }
      /deep/ .el-input__inner{
        height: 36px;
        line-height: 36px;
        background: #EEF0F4;
        border-radius: 4px;
        border: 1px solid #E4E9F0;
      }
      /deep/ .el-input__suffix{
        height: 36px;
        .el-input__suffix-inner{
          line-height: 36px;
          // display: flex;
          // align-items: center;
        }
        .el-select__caret{
          font-size: 0;
          line-height: 38px;
          &:before{
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #66778F;
          }
          &.is-reverse{
            transform: rotateZ(180deg);
          }
        }
      }
      /deep/ .el-checkbox__input{
        .el-checkbox__inner{
          border-radius: 7px;
          width: 16px;
          height: 16px;
          border: 1px solid #7B7D80;
          border-radius: 8px;
          &::after{
            top: 2px;
            left: 5px;
          }
        }
        &.is-checked .el-checkbox__inner{
          background-color: #5080E3;
          border: 1px solid #5080E3;
        }
      }
      /deep/ .el-form-item__content{
        display: flex;
        align-items: center;
        line-height: 36px;
      }
      .el-form-item{
        margin-bottom: 10px;
      }
      .verifyCode{
        .get-code{
          width: 124px;
          height: 36px;
          line-height: 36px;
          background: #4A85FF;
          border-radius: 4px;
          font-size: 16px;
          color: #FFFFFF;
          text-align: center;
          margin-left: 12px;
          cursor: pointer;
          &.number{
            background: #EEF0F4;
            color: #7B7D80;
          }
        }
      }
      .clueform-count{
        height: 22px;
        line-height: 22px;
        font-size: 22px;
        color: #262A31;
        text-align: center;
        margin-top: 7px;
        display: flex;
        justify-content: center;
        img{
          width: 119px;
          height: 22px;
        }
        span{
          font-size: 14px;
          color: #262A31;
          margin-left: 7px;
        }
      }
      .clueform-submit{
        width: 324px;
        height: 48px;
        background: #4A85FF;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 48px;
        text-align: center;
        margin: 9px 0 10px;
        cursor: pointer;
      }
      .clueform-agreement{
        display: flex;
        font-size: 12px;
        color: #7B7D80;
        .clueform-agreement__label{
          margin-left: 8px;
          a{
            text-decoration: none;
            color: #4A85FF;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.service-box{
  width: 100%;
  height: 441px;
  background: #F3F7F9;
  .service-content{
    display: flex;
    .service-item{
      width: calc((100% - 114px) / 4);
      margin-right: 38px;
      img{
        width: 270px;
        height: 281px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.newVisa-box{
  width: 100%;
  height: 370px;
  .newVisa-content{
    position: relative;
    /deep/ .swiper-container {
      position: relative;
      background: initial;
      border-radius: 0;
      // padding-top: 20px;
      height: 260px;
      .swiper-wrapper {
        .swiper-slide {
          // width: 284px !important;
          background: #FFFFFF;
          border: 1px solid #E4E9F0;
          box-sizing: border-box;
          .banner-img{
            width: 284px;
            height: 182px;
          }
          .swiper-info{
            padding: 4px 20px;
            .desc{
              font-size: 16px;
              color: #8F96A0;
              line-height: 22px;
            }
            .item-price{
              display: flex;
              align-items: center;
              justify-content: space-between;
              line-height: 26px;
              margin-top: 8px;
              .item-left{
                font-size: 16px;
                color: #262A31;
                .value{
                  font-weight: 600;
                  font-size: 20px;
                  color: #FF4D18;
                }
              }
              .item-right{
                display: flex;
                align-items: center;
                img{
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                }
                span{
                  font-size: 14px;
                  color: #262A31;
                  margin-left: 8px;
                }
              }
            }
          }
        }
        
      }
    }
    .swiper-button-prev, .swiper-button-next {
      right: 0;
      width: 24px;
      height: 48px;
      background: rgba(0,0,0,0.5);
      color: #fff;
      top: 91px;
      &:after{
        font-size: 20px;
      }
      &.swiper-button-disabled{
        display: none;
      }
    }
    .swiper-button-prev {
      left: 1px;
    }
  }
}
.flowGuide-box{
  width: 100%;
  .title{
    padding: 44px 0 33px;
  }
  .flowGuide-content{
    .flowGuide-tabs{
      display: flex;
      height: 179px;
      background: #FFFFFF;
      box-shadow: 2px 2px 2px 0px rgba(239,239,239,0.5);
      border: 1px solid #E4E9F0;
      .flowGuide-tab{
        position: relative;
        flex: 1;
        text-align: center;
        padding: 0 42px;
        cursor: pointer;
        .flowGuide-tab__index{
          position: absolute;
          top: 0;
          left: 50%;
          color: rgba(198, 200, 213, .3);
          font-weight: 400;
          font-size: 96px;
          font-family: AppleSystemUIFont;
          transform: translateX(-50%);
        }
        .flowGuide-tab__top{
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 21px;
          padding-top: 55px;
          img{
            width: 36px;
            height: 36px;
          }
          .tab-title{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 22px;
            color: #262A31;
            line-height: 30px;
            &.active{
              color: #5080E3;
            }
          }
          .tab__top-line{
            position: absolute;
            bottom: -8px;
            left: 50%;
            width: 36px;
            height: 4px;
            background: #5080E3;
            transform: translateX(-50%);
          }
        }
        .flowGuide-tab__desc{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #7B7D80;
          line-height: 20px;
          text-align: center;
        }
        .flowGuide-tab__triangle{
          position: absolute;
          top: 100%;
          left: 50%;
          z-index: 1;
          width: 25px;
          height: 10px;
          background-color: #fff;
          .arrow{
            position: absolute;
            width: 1px;
            height: 17px;
            background-color: #ececf2;
            border-radius: 2px;
            &.left{
              left: 0;
              transform: translateX(-1px) rotateZ(-50deg);
              transform-origin: top;
            }
            &.right{
              right: 0;
              transform: translateX(1px) rotateZ(50deg);
              transform-origin: top;
            }
          }
        }
        &::after{
          position: absolute;
          top: 50%;
          left: 0;
          width: 18px;
          height: 100px;
          background-image: url(@/assets/line.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          transform: translateY(-50%);
          content: "";
        }
        &:first-child::after{
          display: none;
        }
      }
    }
    .flowGuide-panel{
      display: flex;
      flex-wrap: wrap;
      margin-top: 21px;
      .flowGuide-panel-item{
        width: calc(50% - 11.5px);
        background: #FFFFFF;
        box-shadow: 2px 2px 2px 0px rgba(239,239,239,0.5);
        border: 1px solid #E4E9F0;
        box-sizing: border-box;
        padding: 30px;
        margin-bottom: 16px;
        .flowGuide-title{
          font-size: 20px;
          color: #262A31;
          line-height: 20px;
        }
        .flowGuide-subtitle{
          font-size: 14px;
          color: #7B7D80;
          line-height: 20px;
          margin-top: 10px;
        }
        &:nth-child(2n){
          margin-left: 23px;
        }
      }
    }
  }

}
/deep/ .el-dialog{
  .el-dialog__close{
    font-size: 22px;
  }
  .el-dialog__body{
    padding: 0;
  }
  .dialog-content{
    text-align: center;
    .title{
      font-size: 24px;
      color: #262A31;
      padding: 0;
    }
    .desc{
      font-size: 18px;
      color: #7B7D80;
      margin-top: 10px;
    }
    .qrcode{
      width: 100%;
      height: 240px;
      margin-top: 24px;
      background-image: url('@/assets/success-bg.png');
      background-size: 100% 100%;
      img{
        width: 120px;
        height: 120px;
        margin-top: 24px;
      }
      .qrcode-title{
        font-weight: 600;
        font-size: 20px;
        color: #FFFFFF;
        margin-top: 20px;
      }
      .qrcode-subtitle{
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 23px;
          height: 18px;
          margin-top: 0;
          margin-right: 2px;
        }
      }
    }
  }
}
.title{
  text-align: center;
  padding: 44px 0 42px;
  font-weight: 500;
  font-size: 24px;
  color: #262A31;
  font-family: PingFangSC, PingFang SC;
}
</style>
<style lang="less">
.el-select-dropdown{
  .popper__arrow{
    display: none !important;
  }
  &.el-popper{
    margin: 5px 0 !important;
  }
  .el-select-dropdown__item{
    font-weight: 400;
    font-size: 14px;
    color: #262A31;
    &.hover,&:hover{
      background-color: transparent;
    }
    &.selected{
      color: #4A85FF;
      font-weight: 400 !important;
    }
  }
}
.el-select-dropdown.horizontal{
  .el-select-dropdown__list{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 322px;
    .el-select-dropdown__item{
      padding: 0;
      text-align: center;
      width: 80px;
      height: 46px;
      line-height: 46px;
      font-family: PingFangSC, PingFang SC;
    }
  }
}
.el-select-dropdown.vertical{
  .el-select-dropdown__list{
    .el-select-dropdown__item{
      margin: 0 10px;
      padding: 0 10px;
      height: 61px;
      font-family: PingFangSC, PingFang SC;
      .label{
        font-weight: 500;
        font-size: 14px;
        color: #262A31;
        margin-bottom: 4px;
        line-height: 20px;
        padding-top: 10px;
      }
      .tip{
        font-weight: 400;
        font-size: 12px;
        color: #7B7D80;
        line-height: 17px;
        padding-bottom: 10px;
      }
      &.selected, &:hover{
        background: rgba(212,226,255,0.51);
        border-radius: 2px;
      }
    }
  }
}
.el-message{
  min-width: 200px !important;
  background-color: rgba(0,0,0,0.7) !important;
  border: none !important;
  display: flex;
  justify-content: center;
  padding: 20px 15px !important;
  .el-message__content{
    color: #fff !important;
  }
  .el-message__icon {
    display: none;
  }
}
</style>