import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: index
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "aboutUs" */ '../views/aboutUs/index.vue')
  },
  {
    path: '/aboutUsWhite',
    name: 'aboutUsWhite',
    component: () => import(/* webpackChunkName: "aboutUsWhite" */ '../views/aboutUs/white.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
