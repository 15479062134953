<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <Header></Header>
    <router-view/>
    <Footer></Footer>
    <div class="concat" v-if="data.concat.isShow">
      <a :href="data.concat.jumpUrl" target="_blank">
        <img :src="data.concat.image" alt="" :style="`width:${data.concat.width};height:${data.concat.height};`">
      </a>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  data(){
    return {
      data: window.data
    }
  }
}
</script>
<style>
html,body{
  margin: 0;
}
ul li{
  list-style: none;
}
#app {
  
}

nav {
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.maxWidth{
  max-width: 1200px;
  margin: 0 auto;
}
.concat{
  position: fixed;
  top: 436px;
  right: 0;
  z-index: 999;
}
.concat a{
  display: block;
  text-decoration: none;
}
</style>
