<template>
    <div class="header">
      <div class="header__content maxWidth">
        <img class="cicon" :src="data.logo" alt="">
        <div class="header__right">
          <span
            v-for="(item,index) in data.nav" 
            :key="index"
            :class="{'active': (pathname == '/aboutUs'||pathname=='/aboutUsWhite') ? hash == item.hash : pathname==item.path}"
            @click="goPath(item)">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'el-header',
    components: {
    },
    data(){
      return{
        data: window.data,
        hash: '#about',
      }
    },
    watch :{
      '$route':'getPath',
      'data.document.title':{
        immediate: true,
        handler(newValue,oldValue){
          document.title = newValue
        }
      },
      'data.document.icon':{
        immediate: true,
        handler(n,o){
          // 找到 HTML 页面中的 favicon 标签
          const favicon = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
          // 修改 favicon 的 URL
          favicon.setAttribute('href', this.data.document.icon);
        }
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    computed:{
      isBlack() {
        return location.pathname == '/redAlert' || location.pathname == '/aboutUs'
      },
      pathname(){
        return location.pathname
      },
    },
    methods:{
      getPath(){
        this.hash = this.$route.hash
      },
      goPath(item){
        // if(this.$route.query.id == item.id) return;
        location.href= `${item.path}`
        // this.$router.push({
        //   path: item.path,
        //   // query:{id: item.id}
        // })
      }
    }
  }
  </script>
  <style lang="less" scoped>
    .header{
      width: 100%;
      position: relative;
      z-index: 9;
      .header__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        img{
          width: 154px;
          height: 40px;
        }
        .header__right{
          line-height: 52px;
          span{
            font-weight: 600;
            font-size: 16px;
            color: #66768F;
            line-height: 26px;
            margin-left: 50px;
            display: inline-block;
            cursor: pointer;
            height: 26px;
            display: inline-block;
            font-family: PingFangSC, PingFang SC;
            &.active{
              color: #4A85FF;
            }
          }
        }
      }
    }
  </style>
  