import {
  requestGet,
  requestPost,
  requestPostJson,
  requestGetJson
} from '@/api/function'
const prototypeExpand = {
  install: function (Vue) {
    Vue.prototype.$get = requestGet
    Vue.prototype.$post = requestPost
    Vue.prototype.$postJson = requestPostJson
    Vue.prototype.$getJson = requestGetJson
  }
}

export default prototypeExpand
